
<template>
  <div class="Counter">
    <div class="Total"> {{ Math.trunc(totalCalories) }} Calories</div>
    <div class="ingredients">
      <div v-for="ingredient in ingredients" class="ingredient">
        <span class="name"> {{ ingredient.name }} </span>
        <span class="grams"> {{ Math.trunc(ingredient.grams) }}g </span>
        <span class="kcal"> {{ parseInt(ingredient.calculatedCals, 10) }}kcal</span>
      </div>
    </div>

    <div class="addRecipe" v-if="addRecipeModal">
      <div class="addImage">
        <img class="image" v-if="newRecipe.image" :src="newRecipe.image" width="200" />

        <div v-else class='file-input button'>
          <input type='file' @change="fileSelected">
          <span class="material-symbols-rounded">image</span>
          <!-- <span class='label' data-js-label>No file selected</span> -->
        </div>
      </div>

      <input v-model="newRecipe.name" placeholder="name" />

      <button class="createRecipe" @click="saveRecipe()">
        Save Recipe
      </button>
    </div>

    <button class="createRecipe" @click="addRecipe()">
      New Recipe
    </button>
  </div>
</template>

<script>
export default {
  name: 'CounterPane',
  mounted() {
    console.log(this.totalCalories)

  },
  data() {
    return {
      addRecipeModal: null,
      newRecipe: {
        image: null,
        name: null
      },
      // totalCalories: 0,
    }
  },
  props: {
    ingredients: Array,
    totalCalories: Number
  },
  methods: {
    addRecipe() {
      this.addRecipeModal = true;
      console.log("hello")
      this.$panes['counter'].moveToHeight(705)
    },
    saveRecipe() {

      let recipe = {
        id: Date.now(),
        image: this.newRecipe.image,
        name: this.newRecipe.name,
        ingredients: [...this.ingredients],
        totalCalories: this.totalCalories,

      }
      this.newRecipe = recipe;
      // console.log(this.ingredients, this.totalCalories)
      console.log(this.newRecipe)
      this.$store.commit('addRecipe', this.newRecipe)

      //  document.getElementById("demo").innerHTML =
      //  "Hello " + person + "! How are you today?";

    },

    fileSelected(event) {
      const file = event.target.files.item(0);
      const reader = new FileReader();
      reader.addEventListener('load', this.imageLoader);
      reader.readAsDataURL(file);
    },
    imageLoader(event) {
      this.newRecipe.image = event.target.result;
      console.log(this.newRecipe)
    }

  },
}
</script>

<style lang="scss" scoped>

.addRecipe {
    /* background: red; */
    padding-bottom: 116%;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    align-items: center;
    padding-top: 24px;
}
span.name {
  font-weight: 600;
  width: -webkit-fill-available;

  max-width: 64%;
}

.ingredients div {
  font-size: 13px;
  display: flex;
  /* display: list-item; */
  gap: 11px;
  justify-content: flex-start;

  justify-content: space-between;
}

span.grams {
  color: #14182687;
}

span.kcal {
  color: #3f3e46;
  font-weight: 600;
  width: -webkit-fill-available;
  max-width: 20%;
}

button.createRecipe {
  background: black;
  border: none;
  color: white;
  padding: 12px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  border-radius: 12px;
  margin-top: 24px;
  bottom: 14px;
  right: 18px;
}

.Total {
  font-size: 23px;
  font-weight: 600;
  font-family: Poppins;
}

.file-input {
  /*   font-size: 12px;
  display: inline-block;
  text-align: left;
  padding: 16px;
  width: auto;
  width: -webkit-fill-available;
  position: relative;
  border-radius: 8px;
  color: rgb(51 51 62 / 75%); */


  >[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  /*   >.button {
    display: inline-block;
    cursor: pointer;
    background: #eee;
    padding: 5px 8px;
    border-radius: 2px;
    
    transition: all 0.3s;
    border-radius: 8px;

  } */

  /*   &:hover>.button {
    background: dodgerblue;
    color: white;
  } */
}

.file-input.button {
    background: #0f0f0f;
    background: var(--gray-100);
    color: #e7e7e7;
    color: var(--gray-500);
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    height: 64px;
    width: 64px;
    border-radius: 12px;
    min-width: 64px;
    min-width: 68px;
    height: 68px;
    width: 68px;
    margin: 16px;
}

</style>

